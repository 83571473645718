<template>
    <v-container class="my-10 my-md-16">
        <v-row class="d-flex flex-row pa-3 heading">
            <v-col cols="12">
                <h1 class="text-left">{{ $t("harmony.contactsTitle") }}</h1>
                <p class="text-left">
                    {{ $t("harmony.contactsDesc") }}
                </p>
            </v-col>
        </v-row>

        <v-row class="mb-4 mt-10">
            <v-col>
                <h2 class="text-center mb-4">{{ $t('harmony.allContacts') }}</h2>
                <v-data-table :headers="headers" :items="wppOrEmails" :loading="loading"
                    class="profile rounded-xl mx-3 px-2 py-4" :items-per-page="perPage"
                    :server-items-length="totalWppOrEmails" :page.sync="page" :footer-props="{
                        itemsPerPageOptions: [50, 100, 200, 300, -1],
                        showFirstLastPage: true
                    }" @update:page="fetchWppOrEmails" @update:items-per-page="changePerPage">

                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from '@/axios.js';
export default {
    data() {
        return {
            loading: false,
            page: 1,
            perPage: 50,
            search: '',
            wppOrEmails: [],
            totalWppOrEmails: 0,
        };
    },
    computed: {
        headers() {
            return [
                { text: 'Harmony', value: 'site_info.name' },
                { text: 'Wpp or email', value: 'wpp_or_email' },
                { text: this.$t('harmony.date'), value: 'created_at' },
            ];
        },

    },

    created() {
        this.fetchWppOrEmails();
    },

    methods: {
        async fetchWppOrEmails() {
            this.loading = true;
            try {
                const response = await axios.get('/get-wpp-emails', {
                    params: {
                        page: this.page,
                        per_page: this.perPage,
                        search: this.search,
                        order: this.order,
                        filter: this.filter,
                        list: 'user'
                    }
                });
                this.wppOrEmails = response.data.wppOrEmails;
                this.totalWppOrEmails = response.data.total;
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },


        changePerPage(newPerPage) {
            this.perPage = newPerPage;
            this.page = 1; // Reiniciar a la primera página al cambiar los elementos por página
            this.fetchWppOrEmails();
        },
    },
};
</script>

<style>
.heading h1 {
    font-size: 1.5rem;
    margin-bottom: 5px;
}

.heading p {
    font-size: 0.8rem;
    color: rgb(151, 138, 206);
    font-weight: 600;
}

.profile {
    gap: 20px;
    color: rgb(70, 70, 70);
    -webkit-box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1);
    -moz-box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1);
    box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1);
}

.profile-skeleton { 
    color: rgb(70, 70, 70);
    -webkit-box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1);
    -moz-box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1);
    box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1);
}

.purple-text {
  color: #9786ce;
}

.withdrawal-card {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.v-chip {
  font-size: 0.75rem !important;
}

.caption {
  font-size: 0.75rem !important;
}
</style>